import * as React from "react";
import styles from "./search-component.module.scss";
import { Button } from "../button";

export interface ISearchComponentProps {
  buttonText: string;
  placeholder: string;
  onSearch: (text: string) => void;
  title: string;
}

const SearchComponent = (props: ISearchComponentProps) => {
  let text = "";

  return (
    <div className={styles["search"]}>
      <div className={styles["search__title"]}>
        <h2>{props.title}</h2>
      </div>
      <div className={styles["search__bar-container"]}>
        <input
          onChange={event => {
            text = event.currentTarget.value;
          }}
          type="text"
          placeholder="Typ hier een kernwoord…"
          className={styles["search__bar"]}
        />
        <div className={styles["search__button"]}>
          <Button
            variant={"secondary"}
            onClick={() => {
              props.onSearch(text);
            }}
            text={props.buttonText}
          />
        </div>
      </div>
    </div>
  );
};

export { SearchComponent };
