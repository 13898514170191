import * as React from "react";
import Slider, { Settings } from "react-slick";
import { SectoreCardComponent } from ".";
import { BasicArrow } from "./basic-arrow.component";
import axios from "axios";

export interface ISectoreCardSliderComponentProps {
    history: any
}

const SectoreCardSliderComponent = (props: ISectoreCardSliderComponentProps) => {

  const [categoriesCards, setCategoriesCards] = React.useState<{sectorName: string, image: string, description: string}[]>([]);

  React.useEffect(() => {

      let url = '/api/category/getWithVacanciesCount';
      if (process.env.NODE_ENV === 'development') {
          url = process.env.REACT_APP_BACKEND_URL + url;
      }
      axios.get(url)
          .then(({data}) => {
              setCategoriesCards(data.categories.map((category: any) => {
                  return {
                      sectorName: category.title,
                      image: category.image,
                      description: category.description,
                  };
              }));
          });
  }, []);

  return (
    <div>
      <Slider {...settings}>
        {categoriesCards.map((card, index) => (
          <div key={index}>
            <SectoreCardComponent image={card.image} name={card.sectorName} title={card.sectorName} content={card.description} history={props.history}/>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export { SectoreCardSliderComponent };
const settings: Settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <BasicArrow />,
  prevArrow: <BasicArrow prev />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 3
      }
    }
  ]
};
