import * as React from "react";
import styles from "./team-component-component.module.scss";
import { JoinComponent } from "components/core/join";
import { BodyTextComponent } from "../body-text";
import { PersonCardComponent } from "../person-card";

// @ts-ignore
import pictureWout from "assets/pictures/wout.PNG";
import pictureLuuk from "assets/pictures/luuk.jpg";
import pictureThijs from "assets/pictures/thijs.jpg";
import { Button } from "components/core/button";

export interface ITeamComponentComponentProps {}

const TeamComponentComponent = (props: ITeamComponentComponentProps) => {
  return (
    <div className={styles["team-component"]}>
      <JoinComponent
        componentLeftSide={
          <JoinComponent
            componentLeftSide={
              <BodyTextComponent
                title={"Onze Jobcoaches"}
                text={
                  ""
                }
              />
            }
            componentRightSide={<PersonCardComponent email={"thijs@samsam-mvo.nl"} linkedIn={"thijs-peters"} namePerson={"Thijs Peters"} job={"Accountmanager"} image={pictureThijs} />}
          />
        }
        componentRightSide={
          <JoinComponent
            componentLeftSide={<PersonCardComponent namePerson={"Luuk Mulders"} job={"Adviseur"} image={pictureLuuk} email={"luuk@samsam-mvo.nl"} linkedIn={"luuk-mulders-50329258"} />}
            componentRightSide={<PersonCardComponent namePerson={"Wout Brink"} job={"Job Coach"} image={pictureWout} email={"wout@samsam-mvo.nl"} linkedIn={"woutbrink"} />}
          />
        }
      />
      {/*<div className={styles["team-component__button"]}>*/}
        {/*<Button text={"Meer team"} variant={"secondary"} />*/}
      {/*</div>*/}
    </div>
  );
};

export { TeamComponentComponent };
