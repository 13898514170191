import * as React from "react";
import styles from "./navbar-component.module.scss";
import ReactSVG from "react-svg";
import LOGO_DESKTOP from "assets/icon/logo-desktop.svg";
import LOGO_MOBILE from "assets/icon/logo-mobile.svg";
import { Link } from "react-router-dom";
import { DropdownMenuComponent } from "./dropdown-menu.component";

export interface InavbarComponentProps {
  leftBackground: string;
  rightBackground: string;
}


const NavbarComponent = (props: InavbarComponentProps) => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  return (
    <div className={styles["navbar"]}>
      <div className={styles["navbar__box"]} style={{ backgroundColor: props.leftBackground }}>
        <div className={styles["navbar__logo-desktop"]}>
          <Link to={"/"}>
            <img src={LOGO_DESKTOP} />
          </Link>
        </div>
        <div className={styles["navbar__logo-mobile"]}>
          <Link to={"/"}>
            <img src={LOGO_MOBILE} />
          </Link>
        </div>
      </div>

      <div
        className={`${styles["navbar__box"]} ${styles["navbar__box-left"]}`}
        style={{ backgroundColor: props.rightBackground }}
      >
        <div className={styles["navbar__items"]}>
          {menuItem().map((item, index) => {
            return (
              <Link key={index} to={item.url}>
                  {item.title}
              </Link>
            );
          })}
        </div>
      </div>

      <div className={styles["navbar__icon"]} onClick={() => setIsMenuOpened(!isMenuOpened)}>
        <div />
        <div />
        <div />
      </div>

      <DropdownMenuComponent
        isOpened={isMenuOpened}
        setIsMenuOpened={() => setIsMenuOpened(!isMenuOpened)}
        menuBackground={props.leftBackground}
        items={menuItem()}
      />
    </div>
  );
};

export { NavbarComponent };

const menuItem = (): any[] => [
  { title: "Vacatures", url: "/vacancies" },
  { title: "Reintegratie", url: "/reintegratie" },
  { title: "Sectoren", url: "/sectoren" },
  { title: "Uitzendkracht", url: "/uitzendkracht" },
  { title: "Werkgever", url: "/werkgever" },
  // { title: "Nieuws", url: "/news" },
  { title: "Contact", url: "/contact" }
];
