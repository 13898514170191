import * as React from "react";
import TO_VACATURES_PNG from "assets/icon/toVacatures.png";
import styles from "./category-card-component.module.scss";

export interface ICategoryCardComponentProps {
  sectorName: string;
  icon: string;
  onClick: () => void;
}

const CategoryCardComponent = (props: ICategoryCardComponentProps) => {
  return (
    <div className={styles["category-card"]} onClick={props.onClick}>
      <div className={styles["category-card__container"]}>
        <div className={styles["category-card__icon"]}>
          <img className={styles["category-card__icon-normal"]} src={props.icon} />
          <img className={styles["category-card__icon-to-vacatures"]} src={TO_VACATURES_PNG} />
        </div>
        <div className={styles["category-card__text"]}>
          <h3>{props.sectorName}</h3>
        </div>
      </div>
    </div>
  );
};

export { CategoryCardComponent };
