import * as React from "react";
import ReactDOM from "react-dom";
import styles from "./dropdown-menu-component.module.scss";
import ReactSVG from "react-svg";

import LOGO_MOBILE from "assets/icon/logo-mobile.svg";
import { Link } from "react-router-dom";
export interface DropdownMenuComponentProps {
  menuBackground: string;
  items: any[];
  setIsMenuOpened: () => void;
  isOpened: boolean;
}
const breakpointMedium = 960;
const DropdownMenuComponent = (props: DropdownMenuComponentProps) => {
  function handleResize() {
    if (window.innerWidth > breakpointMedium) {
      props.setIsMenuOpened();
    }
  }
  React.useEffect(() => {
    if (props.isOpened) {
      document.documentElement.style.overflow = "hidden";
      window.addEventListener("resize", handleResize);
    } else {
      document.documentElement.style.overflow = "auto";
      window.removeEventListener("resize", handleResize);
    }
  }, [props.isOpened]);

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div className={`${styles["dropdown-menu"]} ${!props.isOpened ? styles["dropdown-menu-closed"] : ""}`}>
          <div onClick={props.setIsMenuOpened} className={styles["dropdown-menu__icon"]} />
          <div style={{ backgroundColor: props.menuBackground }} className={`${styles["dropdown-menu__content"]} `}>
            <div className={styles["dropdown-menu__logo"]}>
              <ReactSVG src={LOGO_MOBILE} />
            </div>
            {props.items.map((item, index) => {
              return (
                <Link key={index} to={item.url}>
                  <div className={styles["dropdown-menu__items"]}>
                    <h1>{item.title}</h1>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>,
        document.body
      )}
    </React.Fragment>
  );
};

export { DropdownMenuComponent };
