import React from "react";

import styles from "./modal.module.scss";
import modalClosePicture from "assets/pictures/modal-close.svg";

interface IModalProps {
    background?: string,
    children?: any,
    modalId?: any,
    modalOpen?: boolean,
    onModalClose?: any,
    invalid?: boolean
}

const defaultProps: IModalProps = {
    background: '#e0e0e0',
    children: '',
    modalId: Math.random().toString(36).substr(2),
    modalOpen: true,
    onModalClose: null,
    invalid: true,
};

const Modal = (props: IModalProps) => {
    const { background, children, modalId, onModalClose, invalid } = Object.assign(defaultProps , props);
    const handleChange = function(e: React.ChangeEvent<HTMLInputElement>) {
        if (props.modalOpen) {
            console.log('props.onModalClose');
            props.onModalClose();
        }
    };

    return (
        <div className={styles["modal-overlay"]}>
            <input type="checkbox" checked id={ modalId } className={styles["modal-overlay__input"]} onChange={handleChange} />

            {
                props.modalOpen ?
                    <label htmlFor={ modalId } className={styles["modal-overlay__overlay"]}></label>
                    : ''
            }
            {
                props.modalOpen ?
                    <div id={"modal-" + modalId} className={styles["modal-overlay__modal-wrapper"]}>
                        <div className={styles["modal-overlay__modal"]} style={{ backgroundColor: background }}>
                            <div className={styles["modal-overlay__modal-close"]}>
                                <img src={modalClosePicture} onClick={() => onModalClose(false)} />
                                { invalid ? <div className={styles["modal-overlay__modal-invalid"]}><span>X</span><span>Niet alle velden zijn ingevuld.</span></div> : '' }
                            </div>
                            { children }
                        </div>
                    </div>
                    : ''
            }
        </div>
    );
};

export { Modal };