import * as React from "react";
import styles from "./job-card-component.module.scss";
import { Button } from "components/core/button";

export interface IJobCardComponentProps {
  title: string;
  category: string;
  location: string;
  id?: string;
  img?: string;
}

const JobCardComponent = (props: IJobCardComponentProps) => {
  return (
    <div className={styles["job-card"]}>
      {props.img ?
        <div className={styles["job-card__image"]}>
            <div style={{backgroundImage: 'url(' + props.img + ')'}} />
        </div> : ''
      }
      <div className={styles["job-card__text"]}>
        <span>{props.title}</span>
        <span>{props.location}</span>
        <span>{props.category}</span>
      </div>
      <div className={styles["job-card__buttons"]}>
        <div>
          <a href={"/vacancy-view/" + props.id}>
            <Button text="Bekijk" variant={"secondary"} />
          </a>
        </div>

        <div>
          <a href={"/vacancy-view/" + props.id + "?apply=true"}>
            <Button text="Solliciteer" variant={"primary"} />
          </a>
        </div>
      </div>
    </div>
  );
};

export { JobCardComponent };
