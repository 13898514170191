import * as React from "react";
import styles from "./contact-form-component.module.scss";
import { Button } from "components/core/button";
import axios from "axios";
import Select from 'react-select';
import {IIconButtonComponentProps} from "../icon-button";
import PLAY_SVG from "../../../assets/icon/play.svg";

export interface IContactFormComponentProps {
    backgroundColour: string;
}


const
    ContactFormComponent = (props: IContactFormComponentProps) => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');

    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [topicErrorMessage, setTopicErrorMessage] = React.useState('');
    const [messageErrorMessage, setMessageErrorMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');

    const topics = [
        { label: 'Ik wil graag werken', value: 0},
        { label: 'Ik wil graag iemand in dienst nemen', value: 1},
        { label: 'Ik wil bij Samsam werken', value: 2},
        { label: 'Ik heb een andere vraag', value: 3},
    ];
    const [topic, setTopic] = React.useState(topics[0]);

    const selectStyles = {
        container: (styles:any) => ({
            ...styles,
            marginBottom: '18px'
        }),
        control: (styles:any) => ({
            ...styles,
            width: '100%',
            fontSize: '12px',
            lineHeight: '12px',
            padding: '6px 12px',
            outline: 'none',
            fontFamily: 'HelveticaNeue-light',
            borderWidth: '0',
            borderRadius: '0',
            minHeight: '',
        }),
        indicatorsContainer: (styles:any) => ({
            ...styles,
            padding: 0,
        }),
        dropdownIndicator: (styles:any) => ({
            ...styles,
            padding: 0,
        }),
        indicatorSeparator: (styles:any) => ({
            ...styles,
            margin: 0,
        }),
    };

    const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let valid = true;

        if (!firstName) {
            setFirstNameErrorMessage('This field is required!');
            valid = false;
        } else {
            setFirstNameErrorMessage('');
        }

        if (!lastName) {
            setLastNameErrorMessage('This field is required!');
            valid = false;
        } else {
            setLastNameErrorMessage('');
        }

        if (!email) {
            setEmailErrorMessage('This field is required!');
            valid = false;
        } else {
            setEmailErrorMessage('');
        }

        if (!topic) {
            setTopicErrorMessage('This field is required!');
            valid = false;
        } else {
            setTopicErrorMessage('');
        }

        if (!message) {
            setMessageErrorMessage('This field is required!');
            valid = false;
        } else {
            setMessageErrorMessage('');
        }

        if (valid) {
            let url = '/api/contact';
            if (process.env.NODE_ENV === 'development') {
                url = process.env.REACT_APP_BACKEND_URL + url;
            }

            axios.post(url, {
                firstName,
                lastName,
                email,
                topic: topic.label,
                message,
            }).then(({data}) => {
                setEmail("");
                setFirstName("");
                setLastName("");
                setMessage("");
                setSuccessMessage('Bericht is verzonden');
            }).catch((error) => {
                let valid = true;
                //let errorMessages = error && error.response && error.response.data && error.response.data.errors;
                let errorMessages = error;

                if (Array.isArray(errorMessages.firstName) && errorMessages.firstName.length) {
                    setFirstNameErrorMessage(errorMessages.firstName[0]);
                    valid = false;
                }
                if (Array.isArray(errorMessages.lastName) && errorMessages.lastName.length) {
                    setLastNameErrorMessage(errorMessages.lastName[0]);
                    valid = false;
                }
                if (Array.isArray(errorMessages.email) && errorMessages.email.length) {
                    setEmailErrorMessage(errorMessages.email[0]);
                    valid = false;
                }
                if (Array.isArray(errorMessages.topic) && errorMessages.topic.length) {
                    setTopicErrorMessage(errorMessages.topic[0]);
                    valid = false;
                }
                if (Array.isArray(errorMessages.message) && errorMessages.message.length) {
                    setMessageErrorMessage(errorMessages.message[0]);
                    valid = false;
                }

                if (valid) {
                    setErrorMessage(error.message);
                }
            });
        }

        e.stopPropagation();
        e.preventDefault();
    };

    const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onTopicChange = (value: any) => {
        setTopic(value);
    };

    const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };

    return (
        <div className={styles["contact-form"]} style={{ background: props.backgroundColour }}>
            <div className={styles["contact-form__left"]}>
                <div className={styles["contact-form__left__left"]}>
                    Zin om aan de slag te gaan maar moeite bij het vinden van een juiste plek? Heb je werk liggen waar mensen voor nodig zijn? Of ben je als organisatie geïnteresseerd in MVO ondernemen of social return on investment? Neem contact met ons op en zet de eerste stap richting een nieuwe kans. Een kans om deze wereld weer een beetje fijner te maken voor iedereen. Een wereld waarin elke organisatie bijdraagt aan Maatschappelijk Verantwoord Ondernemen.
                </div>
                <div className={styles["contact-form__left__right"]}>
                    &nbsp;
                </div>
            </div>
            <div className={styles["contact-form__right"]}>
                <form>
                    <div className={styles["contact-form__right-input-name"]}>
                        <div className={styles["contact-form__right-input-name__first"]}>
                            <input type="text" placeholder={"Naam"} name="first_name" onChange={onFirstNameChange} required />
                            <div className={styles["contact-form__right-input-name__first-error-msg"]}>&nbsp;{ firstNameErrorMessage }</div>
                        </div>
                        <div className={styles["contact-form__right-input-name__last"]}>
                            <input type="text" placeholder={"Achternaam"} name="last_name" onChange={onLastNameChange} />
                            <div className={styles["contact-form__right-input-name__last-error-msg"]}>&nbsp;{ lastNameErrorMessage }</div>
                        </div>
                    </div>
                    <div className={styles["contact-form__right-input-email"]}>
                        <input type="email" placeholder="E-mailadres" name="email" onChange={onEmailChange} required />
                        <div className={styles["contact-form__right-input-email-error-msg"]}>&nbsp;{ emailErrorMessage }</div>
                    </div>
                    <div className={styles["contact-form__right-input-name"]}>
                        <div className={styles["contact-form__right-input-name__first"]}>
                            <Select
                                value={topic}
                                options={topics}
                                onChange={onTopicChange}
                                name="topic"
                                required
                                className={styles["contact-form__right_input-select"]}
                                styles={selectStyles}
                            />
                        </div>
                        <div className={styles["contact-form__right-input-topic-error-msg"]}>&nbsp;{ topicErrorMessage }</div>
                    </div>
                    <div className={styles["contact-form__right-input-message"]}>
                        <textarea rows={5} placeholder="Bericht..." name="message" onChange={onMessageChange} required />
                        <div className={styles["contact-form__right-input-message-error-msg"]}>&nbsp;{ messageErrorMessage }</div>
                    </div>
                    <div className={styles["contact-form__right-error-message"]}>
                        &nbsp;{ errorMessage }
                    </div>
                    <div className={styles["contact-form__right-success-message"]}>
                        &nbsp;{ successMessage }
                    </div>
                    <div className={styles["contact-form__right-input-button"]}>
                        <Button text="Verstuur" variant={"secondary"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};


export { ContactFormComponent };
