import * as React from "react";
import styles from "./sector-card-component.module.scss";
import { Button } from "components/core/button";
export interface ISectoreCardComponentProps {
  image: string,
  name: string,
  title: string,
  content: string,
  history: any,
}

const SectoreCardComponent = (props: ISectoreCardComponentProps) => {

  console.log('props', props);
  return (
    <div className={styles["sector-card"]} onClick={() => {props.history.push('vacancies?category=' + props.name);}}>
      <div className={styles["sector-card__container"]}>
        <div className={styles["sector-card__container-center"]}>
          <img style={{maxWidth: '70px'}} src={ props.image } />
          <h3>{ props.name }</h3>
        </div>
        <div className={styles["sector-card__container__content"]}>
          <h4>{ props.title }</h4>
          <div className={styles["sector-card__text"]}>
            <span>
                { props.content }
            </span>
          </div>
        </div>
      </div>
      <div className={styles["sector-card__button"]}>
        <Button variant={"secondary"} text={"Bekijk werk"} />
      </div>
    </div>
  );
};

export { SectoreCardComponent };
