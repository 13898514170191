import React from "react";
import styles from "./vacancy-view.module.scss";
import axios from "axios";
import DATUM_PICTURE from "assets/pictures/vacancy-datum.png";
import SECTOR_PICTURE from "assets/pictures/vacancy-sector.png";
import LOCATION_PICTURE from "assets/pictures/vacancy-location.png";
import CONTACT_PICTURE from "assets/pictures/vacancy-contact.png";
import INSTAGRAM_SVG from "assets/icon/instagram.svg";
import FACEBOOK_SVG from "assets/icon/facebook.svg";
import LINKEDIN_SVG from "assets/icon/linkedin.svg";
import { RelatedVacanciesComponent } from "components/modules/related-vacancies";
import { Modal } from "components/core/modal";
import { Button } from "components/core/button";
import { DropDownComponent } from "../../core/drop-down";
import { NavbarComponent } from "components/modules/navbar";
import { FooterComponent } from "components/modules/footer";

const VacancyViewPage = (props: any) => {
    const [vacancy, setVacancy] = React.useState<any>({
        internal_title: '',
        description: '',
        manager: {
            first_name: '',
            last_name: '',
            email: '',
            image: ''
        },
        city: '',
        country: '',
        postal_code: '',
        province: '',
        category: {
            title: '',
            image: '',
            job_category: '',
        },
        professional_name: '',
        updated_at: ''
    });
    const [passedDays, setPassedDays] = React.useState(0);
    const [relatedVacancies, setRelatedVacancies] = React.useState([]);
    const [applicationSuccess, setApplicationSuccess] = React.useState(false);
    const [showApplicationModal, setShowApplicationModal] = React.useState(false);
    const [vacancyId] = React.useState(props.match.params.id);

    React.useEffect(() => {

        let searchParams = props.location.search;
        searchParams = searchParams.length > 1 ? searchParams.substring(1) : searchParams;
        let segments = searchParams.split('&');
        segments.forEach((segment: any) => {
            let key = segment.split('=')[0];
            let value = segment.split('=')[1];
            if (key === 'apply' && value === 'true') {
                setShowApplicationModal(true);
            }
        });

        let url = '/api/vacancy/' + vacancyId + '/detail';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                setVacancy(data.vacancy);

                let date1 = new Date(data.vacancy.updated_at);
                let date2 = new Date();

                let differenceInTime = date2.getTime() - date1.getTime();
                let differenceInDays = differenceInTime / (1000 * 3600 * 24);

                setPassedDays(Math.floor( differenceInDays ));
            });

        url = '/api/vacancy/list';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                let vacancies = data.data.filter((vacancy: any) => {
                    return vacancy.id != vacancyId;
                }).map((vacancy: any) => {
                    return {
                        id: vacancy.id,
                        title: vacancy.internal_title,
                        category: vacancy.category.title,
                        location: `${vacancy.province} ${vacancy.postal_code} ${vacancy.city} ${vacancy.country}`,
                        img: vacancy.category.image,
                    };
                });
                setRelatedVacancies(vacancies);
            });
    }, []);

    const onContact = function() {
        window.location.href = "/contact";
    };

    const back = function() {
        props.history.goBack();
    };

    const [valid, setValid] = React.useState(true);
    const [showError, setShowError] = React.useState(false);

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [province, setProvince] = React.useState('');
    const [residence, setResidence] = React.useState('');
    const [profession, setProfession] = React.useState('');
    const [resume, setResume] = React.useState('');

    const [hasFileSizeError, setHasFileSizeError] = React.useState(false);

    const onChangeHandler = function(event: React.ChangeEvent<HTMLInputElement>) {
        if (event && event.target && event.target.files) {
            let file = event.target.files[0];
            let fileSizeIsTooBig = ((file.size / 1024 / 1024) - 2.5) >= 0;
            setHasFileSizeError(fileSizeIsTooBig);
            if (!fileSizeIsTooBig) {
                let data = new FormData();
                data.append('data', file, file.name);

                let url = '/api/upload/resume';
                if (process.env.NODE_ENV === 'development') {
                    url = process.env.REACT_APP_BACKEND_URL + url;
                }
                axios.post(url, data)
                    .then(({data}) => {
                        setResume(data.path);
                    });
            }
        }
    };

    const validateAndSend = function() {
        let valid = !!firstName && !!lastName && !!email && !!phoneNumber && !!province && !!residence && !!profession;
        setValid(valid);
        setShowError(true);

        if (valid) {
            let url = '/api/applicant';
            if (process.env.NODE_ENV === 'development') {
                url = process.env.REACT_APP_BACKEND_URL + url;
            }
            axios.post(url, {
                first_name: firstName,
                last_name: lastName,
                email,
                phone: phoneNumber,
                province,
                residence,
                profession,
                resume,
                vacancy_id: vacancyId,
            }).then(({data}) => {
                setApplicationSuccess(true);
            });
        }
    };

    return (
        <div className={styles["vacancy-view"]}>
            <NavbarComponent leftBackground={"#54cefd"} rightBackground={"#54cefd"} />
            <div className={styles["vacancy__title"]} style={{ backgroundColor: "#54cefd" }}>
                <h1>
                    { vacancy.internal_title }
                </h1>
            </div>
            <div className={styles["vacancy__attr-bar"]}>
                <div className={styles["vacancy__attr-bar-item"]}>
                    <img src={DATUM_PICTURE} />
                    <div>
                        <div className={styles["vacancy__attr-bar-item-exp1"]}>Datum</div>
                        <div className={styles["vacancy__attr-bar-item-exp2"]}>{ passedDays } dag(en) geleden</div>
                        <div className={styles["vacancy__attr-bar-item-exp3"]}>{ vacancy.updated_at ? (new Date(vacancy.updated_at)).toLocaleDateString('nl-nl', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : '' }</div>
                    </div>
                </div>
                <div className={styles["vacancy__attr-bar-item"]}>
                    <img src={ SECTOR_PICTURE } />
                    <div>
                        <div className={styles["vacancy__attr-bar-item-exp1"]}>Sector</div>
                        <div className={styles["vacancy__attr-bar-item-exp2"]}>{ vacancy.category.title }</div>
                        <div className={styles["vacancy__attr-bar-item-exp3"]}>{ vacancy.professional_name }</div>
                    </div>
                </div>
                <div className={styles["vacancy__attr-bar-item"]}>
                    <img src={LOCATION_PICTURE} />
                    <div>
                        <div className={styles["vacancy__attr-bar-item-exp1"]}>Locatie</div>
                        <div className={styles["vacancy__attr-bar-item-exp2"]}>{ vacancy.city }</div>
                        <div className={styles["vacancy__attr-bar-item-exp3"]}>{ vacancy.postal_code + ', ' + vacancy.province }</div>
                    </div>
                </div>
                <div className={styles["vacancy__attr-bar-item"]}>
                    <img src={ CONTACT_PICTURE } />
                    <div>
                        <div className={styles["vacancy__attr-bar-item-exp1"]}>Contactpersoon</div>
                        <div className={styles["vacancy__attr-bar-item-exp2"]}>{ vacancy.manager.first_name + ' ' + vacancy.manager.last_name }</div>
                        <div className={styles["vacancy__attr-bar-item-exp3"]}>Accountmanager</div>
                    </div>
                </div>
            </div>

            <div className={styles["vacancy__actions-bar"]}>
                <div className={styles["vacancy__actions-bar-left"]}>
                    <Button text="Terug" variant={"secondary"} onClick={back} />
                </div>
                <div className={styles["vacancy__actions-bar-right"]}>
                    <Button text="Contact" onClick={onContact} variant={"secondary"} />
                    <Button text="Solliciteer nu" onClick={() => setShowApplicationModal(true)} variant={"primary"} />
                </div>
            </div>

            <div className={styles["vacancy__description-header"]}>
                Omschrijving
            </div>

            <div className={styles["vacancy__description-body"]}>
                <div className={styles["vacancy__description-body__content"]}>
                    <div className={styles["vacancy__description-body__content-block"]}>
                        <div className={styles["vacancy__description-body__content-block-left"]}>
                            <div className={styles["vacancy__description-body__content-block-left-title"]}>Algemeen</div>
                            <div className={styles["vacancy__description-body__content-block-left-description"]} dangerouslySetInnerHTML={{__html: vacancy.description}} />
                        </div>
                        <div className={styles["vacancy__description-body__content-block-right"]}>
                            <div className={styles["vacancy__description-body__content-block-right-title"]}>De functie</div>
                            <div className={styles["vacancy__description-body__content-block-right-description"]} dangerouslySetInnerHTML={{__html: vacancy.description_function}} />
                        </div>
                    </div>

                    <div className={styles["vacancy__description-body__content-block"]}>
                        <div className={styles["vacancy__description-body__content-block-left"]}>
                            <div className={styles["vacancy__description-body__content-block-left-title"]}>De kandidaat die we zoeken is:</div>
                            <div className={styles["vacancy__description-body__content-block-left-description"]} dangerouslySetInnerHTML={{__html: vacancy.description_candidate}} />
                        </div>
                        <div className={styles["vacancy__description-body__content-block-right"]}>
                            <div className={styles["vacancy__description-body__content-block-right-title"]}>Wat bieden wij jou?</div>
                            <div className={styles["vacancy__description-body__content-block-right-description"]} dangerouslySetInnerHTML={{__html: vacancy.description_offer}} />
                        </div>
                    </div>

                    <div className={styles["vacancy__description-body__content-block"]}>
                        <div className={styles["vacancy__description-body__content-block-left"]}>
                            <div className={styles["vacancy__description-body__content-block-left-title"]}>Wie zoeken we?</div>
                            <div className={styles["vacancy__description-body__content-block-left-description"]} dangerouslySetInnerHTML={{__html: vacancy.description_expectation}} />
                        </div>
                        <div className={styles["vacancy__description-body__content-block-right"]}>
                            <div className={styles["vacancy__description-body__content-block-right-title"]}>Wie zijn wij?</div>
                            <div className={styles["vacancy__description-body__content-block-right-description"]} dangerouslySetInnerHTML={{__html: vacancy.description_who}} />
                        </div>
                    </div>
                </div>
                <div className={styles["vacancy__description-body__extra_block"]}>
                    <div className={styles["vacancy__description-body__extra_block-header"]}>Social Media</div>
                    <div className={styles["vacancy__description-body__extra_block-body"]}>
                        <div className={styles["vacancy__description-body__extra_block-body__sentence"]}>Volg ons op:</div>
                        <div className={styles["vacancy__description-body__extra_block-body__social-icons"]}>
                            <a href={process.env.REACT_APP_INSTAGRAM}><img src={INSTAGRAM_SVG} /></a>
                            <a href={process.env.REACT_APP_FACEBOOK}><img src={FACEBOOK_SVG} /></a>
                            <a href={process.env.REACT_APP_LINKEDIN}><img src={LINKEDIN_SVG} /></a>
                        </div>
                    </div>
                </div>
                <div className={styles["vacancy__description-body__data"]}>
                    <div className={styles["vacancy__description-body__data-header"]}>Gegevens</div>
                    <div className={styles["vacancy__description-body__data-body"]}>
                        <div className={styles["vacancy__description-body__data-body__sentence"]}>Kerkenbos 1075V, 6546BB, Nijmegen</div>
                        <div className={styles["vacancy__description-body__data-body__sentence"]}>welkom@samsam-mvo.nl</div>
                        <div className={styles["vacancy__description-body__data-body__sentence"]}>+31 (0) 88 711 33 33</div>
                    </div>
                </div>
            </div>

            <RelatedVacanciesComponent jobsCards={relatedVacancies} />

            <FooterComponent />

            {
                !applicationSuccess ?
                    <Modal invalid={!valid}
                           modalOpen={showApplicationModal}
                           onModalClose={() => setShowApplicationModal(false)}
                    >
                        <div className={styles["vacancy__apply-modal__body"]}>
                            <div className={styles["vacancy__apply-modal__body-title"]}>
                                Sollicitatie voor: { vacancy.internal_title }
                            </div>
                            <div className={styles["vacancy__apply-modal__body-row"]}>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!firstName && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <input type="text" placeholder='Voornaam' value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!province && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <DropDownComponent
                                            items={['Gelderland', 'Utrecht', 'Noord-Holland', 'Zuid-Holland', 'Noord-Brabant', 'Zeeland', 'Limburg', 'Overijssel', 'Groningen', 'Flevoland', 'Friesland', 'Drenthe']}
                                            activeItem={province}
                                            placeholder='Provincie'
                                            color={'#000'}
                                            backgroundColor={'#fff'}
                                            onItemUpdate={setProvince}
                                        />
                                        {/*<input type="text" placeholder='Provincie' value={province} onChange={e => setProvince(e.target.value)} />*/}
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                            </div>
                            <div className={styles["vacancy__apply-modal__body-row"]}>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!lastName && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <input type="text" placeholder='Achternaam' value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!profession && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <input type="text" placeholder='Beroep' value={profession} onChange={e => setProfession(e.target.value)} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                            </div>
                            <div className={styles["vacancy__apply-modal__body-row"]}>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!email && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <input type="email" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!residence && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <input type="text" placeholder='Woonplaats' value={residence} onChange={e => setResidence(e.target.value)} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                            </div>
                            <div className={styles["vacancy__apply-modal__body-row"]}>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!phoneNumber && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                        <input type="text" placeholder='Telefoon' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                                <div className={styles["vacancy__apply-modal__body-row-input"] + (!resume && showError ? (' ' + styles['vacancy__apply-modal__body-row-input-has-error']) : '')}>
                                    <div className={hasFileSizeError ? styles["vacancy__apply-modal__body-row-input-file-upload-has-file-size-error"] : styles["vacancy__apply-modal__body-row-input-file-upload"] }>
                                        <div className={styles["vacancy__apply-modal__body-row-input-wrapper"]}>
                                            <input disabled placeholder={ 'Selecteer CV (Max 2,5 MB)' } />

                                            <div className={styles['vacancy__apply-modal__body-row-input-file-upload__error-msg']}>
                                                <span>X</span>
                                                <span>Fout. Bestand te groot. (Max 2,5 MB)</span>
                                            </div>
                                        </div>
                                        <input type='file' id='file' name='file' className={'vacancy__apply-modal__body-row-input-file-upload-uploader'} onChange={onChangeHandler} />
                                    </div>
                                    <div className={styles['vacancy__apply-modal__body-row-input__error-msg']}>Verplicht veld</div>
                                </div>
                            </div>
                            <div className={styles["vacancy__apply-modal__body-row"]}>
                                <div className={styles["vacancy__apply-modal__body-row-input"]}></div>
                                <div className={styles["vacancy__apply-modal__body-row-input"]}>
                                    <Button text="Versturen" variant={"primary"} onClick={validateAndSend}/>
                                </div>
                            </div>
                        </div>
                    </Modal> : ''
            }

            {
                applicationSuccess ?
                    <Modal modalOpen={showApplicationModal}
                           onModalClose={() => setShowApplicationModal(false)}>
                        <div className={styles["vacancy__apply-modal__body"]}>
                            <div className={styles["vacancy__apply-modal__body-title"]}>
                                Sollicitatie voor: { vacancy.internal_title }
                            </div>

                            <div className={styles["vacancy__apply-modal__body-row"]}>
                                <div className={styles["vacancy__apply-modal__body-row__success_message"]}>
                                    <div>Gelukt! Bedankt voor je sollicitatie. We houden contact.</div>
                                </div>
                            </div>
                        </div>
                    </Modal> : ''
            }
        </div>
    );
};

export { VacancyViewPage };
