import * as React from "react";
import styles from "./drop-down-component.module.scss";
import axios from "axios";

export interface IDropDownComponentProps {
    items: any[],
    activeItem?: string,
    color: string,
    backgroundColor: string,
    placeholder: string,
    onItemUpdate: any,
}

const DropDownComponent = (props: IDropDownComponentProps) => {
    const [isMenuOpened, setIsMenuOpened] = React.useState(false);
    const selectedItem = props.activeItem || props.placeholder;

    const onClickItem = function(e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) {
        setIsMenuOpened(false);
        props.onItemUpdate(item);
    };

    return (
        <div className={styles["drop-down"]} onBlur={() => setIsMenuOpened(false)} tabIndex={0}>
            <div className={styles["drop-down__click"]} onClick={() => setIsMenuOpened(!isMenuOpened)} style={{ backgroundColor: props.backgroundColor, color: selectedItem === props.placeholder ? 'grey' : props.color }}>
                <span>{ selectedItem }</span>
                { isMenuOpened ? <span className={styles["drop-down__click-caret-up"]} /> : <span className={styles["drop-down__click-caret-down"]} />}
            </div>

            { isMenuOpened ? (
                <div className={styles["drop-down__menu"]}>
                    <div className={styles["drop-down__menu-wrapper"]} style={{ backgroundColor: props.backgroundColor, color: props.color }}>
                        {props.items.map((item, i) =>
                            <div className={styles["drop-down__menu-item"]} key={i} onClick={e => onClickItem(e, item)}>
                                {item}
                            </div>
                        )}
                    </div>
                </div>
            ) : '' }
        </div>
    );
};

export { DropDownComponent };
