import * as React from "react";
import styles from "./hight-cta-component.module.scss";

export interface IHightCtaComponentProps {
  image: string;
  topContent: JSX.Element;
  bottomContent: JSX.Element;
  position?: "left" | "right";
}

const HightCtaComponent = (props: IHightCtaComponentProps) => {
  return (
    <div className={styles["hight-cta"]}>
      <div className={styles["hight-cta__content"]}>
        <div> {props.topContent}</div>
        <div> {props.bottomContent}</div>
      </div>
      <div
        className={`${styles["hight-cta__image"]} ${
          props.position ? styles[`hight-cta__image-${props.position}`] : styles["hight-cta__image-left"]
        }`}
      >
        <img src={props.image} />
      </div>
    </div>
  );
};

export { HightCtaComponent };
