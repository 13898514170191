import * as React from "react";
import styles from "./body-text-2-component.module.scss";
import { ExecFileOptionsWithStringEncoding } from "child_process";
import { Button } from "components/core/button";

export interface IBodyTextComponentProps {
  text: string;
  uppertext: string;
  text2:string;
  isSplit?: boolean;
  background?: string;
  title?: string;
  title2? : string;
  date?: string;
  buttonText?: string;
  buttonLink?: string;
  contentMaxHeight?: string;
}

const BodyText2Component = (props: IBodyTextComponentProps) => {
  return (
    <div className={styles["body-text"]} style={{ backgroundColor: props.background }}>
      <div className={styles["body-text__uppertext"]}>
        <p>{props.uppertext}</p>
      </div>
      <div className={styles["body-text__content"]}>
        {props.title && (
          <div className={styles["body-text__title"]}>
            <h2>{props.title}</h2>
          </div>
        )}
        <p className={`${props.isSplit ? styles["body-text__split"] : ""}`} style={props.contentMaxHeight ? {maxHeight: props.contentMaxHeight, overflow: 'hidden'} : {}}
           dangerouslySetInnerHTML={{__html: props.text}}
        />
        {props.date && (
          <div className={styles["body-text__date"]}>
            <span>{props.date}</span>
          </div>
        )}
      </div>
      <div className={styles["body-text__content"]}>
        {props.title2 && (
            <div className={styles["body-text__title"]}>
              <h2>{props.title2}</h2>
            </div>
        )}
        <p className={`${props.isSplit ? styles["body-text__split"] : ""}`} style={props.contentMaxHeight ? {maxHeight: props.contentMaxHeight, overflow: 'hidden'} : {}}
           dangerouslySetInnerHTML={{__html: props.text2}}
        />
        {props.date && (
            <div className={styles["body-text__date"]}>
              <span>{props.date}</span>
            </div>
        )}
      </div>
      {props.buttonText && (
        <div className={styles["body-text__button"]}>
          <a href={props.buttonLink}>
            <Button text={props.buttonText} variant={"secondary"} />
          </a>
        </div>
      )}
    </div>
  );
};

export { BodyText2Component };
