import * as React from "react";
import styles from "./related-vacancies-component.module.scss";
import { IJobCardComponentProps, JobCardComponent } from "components/core/job-card";

export interface IRelatedVacanciesComponentProps {
  jobsCards: IJobCardComponentProps[];
}

const RelatedVacanciesComponent = (props: IRelatedVacanciesComponentProps) => {
  return (
    <div className={styles["related-vacancies"]}>
      <div className={styles["related-vacancies__title"]}>
        <h2>Gerelateerde vacatures</h2>
      </div>
      <div className={styles["related-vacancies__cards-container"]}>
          {props.jobsCards.filter((card, idx) => idx < 6).map((card, index) => (
          <JobCardComponent
            key={index}
            id={card.id}
            category={card.category}
            title={card.title}
            location={card.location}
          />
        ))}
      </div>
    </div>
  );
};

export { RelatedVacanciesComponent };
