import * as React from "react";
import styles from "./welcome-component.module.scss";
import { Button } from "components/core/button/button-component";

export interface IWelcomeComponentProps {
  leftText: string;
  rightText: string;
  leftBackground: string;
  rightBackground: string;
  titleLeftButton?: string;
  linkLeftButton?: string;
  titleRightButton?: string;
  pictures?: [string, string];
  linkRightButton?: string;
}

const WelcomeComponent = (props: IWelcomeComponentProps) => {
  return (
    <div className={styles["welcome"]}>
      <div className={styles["welcome__box"]} style={{ backgroundColor: props.leftBackground }}>
        <div className={styles["welcome__text"]}>
          <h1>{props.leftText}</h1>
        </div>
        {props.titleLeftButton && (
          <div className={styles["welcome__button"]}>
            <a href={props.linkLeftButton}>
              <Button text={props.titleLeftButton} variant={"secondary"} />
            </a>
          </div>
        )}
      </div>

      <div className={styles["welcome__box"]} style={{ backgroundColor: props.rightBackground }}>
        <div className={styles["welcome__text"]}>
          <h1>{props.rightText}</h1>
        </div>
        {props.titleRightButton && (
          <div className={styles["welcome__button"]}>
            <a href={props.linkRightButton}>
              <Button text={props.titleRightButton} variant={"secondary"} />
            </a>
          </div>
        )}
      </div>

      {props.pictures &&
        props.pictures.map((pic, index) => {
          return (
            <div
              className={styles[`welcome__picture-${index}`]}
              style={{ backgroundImage: `url(${pic})` }}
              key={index}
            />
          );
        })}
    </div>
  );
};

export { WelcomeComponent };
