import React from "react";

import { NavbarComponent } from "components/modules/navbar";

import { FooterComponent } from "components/modules/footer";
import { TitleComponent } from "components/modules/title";
import noMatching from "assets/pictures/404.png";
import styles from "./no-match-component.module.scss";
import { Button } from "components/core/button";

const NoMatchPage = (props: any) => {

  const goBack = function() {
    window.history.back();
  };

  return (
    <div>
      <NavbarComponent leftBackground={"#e0e0e0"} rightBackground={"#e0e0e0"} />
      <TitleComponent title={"Error 404"} backgroundColour={"#e0e0e0"} />
      <div className={styles["no-match"]}>
          <div className={styles["no-match-container"]}>
              <img src={noMatching}/>
              <div className={styles["no-match-container-caption"]}>Oeps, daar ging iets mis. De pagina die u zoekt is niet meer te bezoeken.</div>
          </div>
          <div className={styles["no-match-button"]}>
              <Button text="Ga terug" onClick={goBack} variant={"primary"} />
          </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export { NoMatchPage };
