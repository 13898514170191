import * as React from "react";
import styles from "./vacancies-filter-component.module.scss";
import vacanciesFilterPictureUser from "assets/pictures/vacancies-filter-picture-user.svg";
import { DropDownComponent } from "../../core/drop-down";
import { Button } from "components/core/button/button-component";
import axios from "axios";

export interface VacanciesFilterComponentProps {
    title: string,
    background: string,
    searchTerm?: string,
    categoryTerm?: string,
    search: (term: string, province: string, city: string, category: string) => void
}

const VacanciesFilterComponent = (props: VacanciesFilterComponentProps) => {
    const [search, setSearch] = React.useState(props.searchTerm || '');
    const [city, setCity] = React.useState('');
    const [province, setProvince] = React.useState('');
    const [provinces, setProvinces] = React.useState([]);
    const [category, setCategory] = React.useState(props.categoryTerm || '');
    const [categories, setCategories] = React.useState([]);

    React.useEffect(() => {
        props.search(search, province, city, props.categoryTerm || '');
    }, [props.categoryTerm]);

    const setSearchValue = function(e: any) {
        setSearch(e.target.value);
    };

    const setCityValue = function(e: any) {
        setCity(e.target.value);
    };

    React.useEffect(() => {
        let url = '/api/province/list';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                setProvinces(data.data);
            });
    }, []);

    React.useEffect(() => {
        let url = '/api/category/all';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                setCategories(data.data);
            });
    }, []);

    return (
        <div className={styles["vacancies-filter"]} style={{ backgroundColor: props.background }}>

            <div className={styles["vacancies-filter__title"]}>
                {props.title}
            </div>

            <div className={styles["vacancies-filter__picture"]}>
                <div className={styles["vacancies-filter__picture-user"]}>
                    <img src={vacanciesFilterPictureUser} />
                </div>
            </div>

            <div className={styles["vacancies-filter__search"]}>
                <div className={styles["vacancies-filter__search-container"]}>
                    <div className={styles["vacancies-filter__search-input"]}>
                        <input placeholder="Zoek term" value={search} onChange={setSearchValue} />
                    </div>

                    <div className={styles["vacancies-filter__search-drop-down"]}>
                        <DropDownComponent
                            items={provinces}
                            activeItem={province}
                            placeholder='Provincie'
                            color={'#000'}
                            backgroundColor={'#fff'}
                            onItemUpdate={setProvince}
                        />
                    </div>

                    <div className={styles["vacancies-filter__search-input"]}>
                        <input placeholder="Plaats" value={city} onChange={setCityValue} />
                    </div>

                    <div className={styles["vacancies-filter__search-drop-down"]}>
                        <DropDownComponent
                            items={categories}
                            activeItem={category}
                            placeholder='Sector'
                            color={'#000'}
                            backgroundColor={'#fff'}
                            onItemUpdate={setCategory}
                        />
                    </div>

                    <div className={styles["vacancies-filter__search-button"]}>
                        <Button text="Zoeken" variant={"secondary"} onClick={() => props.search(search, province, city, category)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { VacanciesFilterComponent };
