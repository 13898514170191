import * as React from "react";
import styles from "./search-by-category-component.module.scss";
import { ICategoryCardComponentProps, CategoryCardComponent } from "components/core/category-card";
import { Button } from "components/core/button";

export interface ISearchByCategoryComponentProps {
  categoryNames: ICategoryCardComponentProps[];
  onSearch: (text: string) => void;
}

const SearchByCategoryComponent = (props: ISearchByCategoryComponentProps) => {
  return (
    <div className={styles["search-by-category"]}>
      <div className={styles["search-by-category__title"]}>
        <h2>Zoek op categorie</h2>
      </div>
      <div className={styles["search-by-category__categories-container"]}>
        {props.categoryNames.map((card, index) => (
          <div key={index} className={`${index > 3 ? styles["search-by-category__no-visible-mobile"] : ""}`}>
            <CategoryCardComponent key={index} sectorName={card.sectorName} icon={card.icon} onClick={() => props.onSearch(card.sectorName) }/>
          </div>
        ))}
        <i className={styles["search-by-category__no-visible-mobile"]} aria-hidden={"true"} />
        <i className={styles["search-by-category__no-visible-mobile"]} aria-hidden={"true"} />
        <i className={styles["search-by-category__no-visible-mobile"]} aria-hidden={"true"} />
      </div>
      <div className={styles["search-by-category__button"]}>
        <Button text={"Meer sectoren"} variant={"secondary"} />
      </div>
    </div>
  );
};

export { SearchByCategoryComponent };
