import React from "react";

import PLAY_SVG from "assets/icon/play.svg";
import { NavbarComponent } from "components/modules/navbar";

import { FooterComponent } from "components/modules/footer";
import pictuere1 from "assets/pictures/business-person5.jpg";
import { BodyTextComponent } from "components/modules/body-text";
import { WelcomeTitlesComponent } from "components/modules/welcome-titles";
import { JoinComponent } from "components/core/join";
import { CollapsibleBlockComponent } from "components/modules/collapsible-block";
import { ICollapsibleComponentProps } from "components/core/collapsible";
import { HightCtaComponent } from "components/modules/hight-cta";
import { IIconButtonComponentProps } from "components/modules/icon-button";
import { IconButtonsBlockComponent } from "components/modules/icon-buttons-block";
const AlgemenVoorwaarden = "/documents/algemenen.pdf";
const Urenbrief = "/documents/urenbrief.pdf";
const Maatschappelijk = "/documents/Maatschappelijk_Verantwoord_Ondernemen.pdf";
const onSearchCategory = (text: string) => {
  // category search, move it the the parent component
  alert(text);
};
const WerkgeverPage = () => {
  return (
    <div>
      <NavbarComponent leftBackground={"#54cefd"} rightBackground={"#54cefd"} />
      <WelcomeTitlesComponent
        leftBackground={"#54cefd"}
        rightBackground={"#54cefd"}
        leftText={"De werkgever"}
        rightText={"Samen vinden wij het personeel dat het beste bij je past en elke organisatie verrijkt."}
      />

      <HightCtaComponent
        position={"right"}
        topContent={
          <BodyTextComponent
            isSplit
            title={"Informatie"}
            text={
              "De specialiteit van Samsam is het vinden van het juiste personeel voor ondernemers en werkgevers. Wij manoeuvreren ons binnen een arbeidsmarkt die constant in beweging is en met de dag kan verschillen van aanbod. Dit kan betekenen dat het vinden van de geschikte werknemer bij een openstaande vacature soms lang kan duren of meer energie dan nodig gaat kosten. Wij gaan deze strijd graag aan en besparen je de tijd en de moeite die deze inspanning kan kosten. Het runnen van een succesvol bedrijf is leuk, motiverend en kan je een goed gevoel van trots bezorgen. Hoe fijn zou het zijn als je dit gevoel van trots kan delen met anderen en samen iets kan betekenen voor de maatschappij? Samsam kan je hiermee helpen. Wij zijn de schakel tussen jou als werkgever en de personen met een afstand tot de arbeidsmarkt om zo samen voor iedereen een plek te bieden binnen onze maatschappij. "
            }
            buttonLink={'/contact'}
            buttonText={"Neem contact op"}
          />
        }
        bottomContent={<IconButtonsBlockComponent iconButtons={iconButtonDownload()} />}
        image={pictuere1}
      />
      <br />
      <br />
      <br />
      <br />
      <JoinComponent
        componentLeftSide={
          <BodyTextComponent
            isSplit
            title={"Het proces"}
            text={
              "Het proces is misschien net even iets anders als je gewend bent, vandaar dat wij deze begeleiding en ondersteuning van dit proces tot een van onze prioriteiten rekenen.Het team van Samsam bestaat uit een groep gemotiveerde en ervaren loopbaanbegeleiders die constant op zoek zijn naar nieuw talent en nieuwe mogelijkheden voor deze talenten. Onder leiding van ervaren ondernemer, Simon Rikmenspoel, beschikt het team over jaren ervaring binnen de HR-Industrie. Jaren aan ervaring die je de mogelijkheid geven zich enkel te richten op het ondernemen, wij regelen het werven van nieuw personeel voor jou.Wij gaan graag met je in gesprek wat Samsam voor jou kan betekenen!"
            }
          />
        }
        componentRightSide={
          <BodyTextComponent
            isSplit
            title={"Payrolling"}
            text={
              "Naast het vinden van het juiste personeel biedt Samsam de mogelijkheid om het juridisch werkgeverschap van je over te nemen. Dit werkt als volgt; Op papier zijn de medewerkers in dienst bij ons, maar ze werken bij jou op de werkvloer. Dit bespaart je tijd en moeite en je loopt minder risico. In deze constructie werf je zelf je medewerkers, maakt afspraken over hun salaris en meldt ze vervolgens bij ons aan. Wij zorgen daarna voor de rest: van arbeidscontract opstellen en salaris uitbetalen tot werkgeverslasten afdragen en verzuim begeleiden. Op deze manier hoef je je nergens meer druk over te maken en kun je de focus houden bij het ondernemen."
            }
          />
        }
      />

      <JoinComponent
        componentLeftSide={
          <BodyTextComponent
            isSplit
            title={"WAB 2020"}
            text={
              "Samsam werkt geheel conform de vernieuwde Wet Arbeidsmarkt in Balans (WAB), die vanaf 1 januari 2020 in werking is getreden. De WAB maakt onderscheid tussen payrolling en uitzenden, waar dat voorheen niet gebeurde. De WAB heeft grote gevolgen voor de primaire en secundaire arbeidsvoorwaarden alsmede de flexibiliteit die geboden wordt door de ruimere ketenregeling die nu nog voor uitzendbureaus geldt. Samsam lost dit op door een specifieke verloning oplossing aan te bieden die geheel conform WAB is. Deze oplossing bieden wij door:\n" +
              "<ul style='list-style-position: inside;'><li>Controleren inlener en welke CAO deze is ingedeeld, op basis daarvan de medewerkers indelen bij de desbetreffende CAO en de voorwaarden van de CAO te handhaven, waardoor gegarandeerd wordt dat alle medewerkers eerlijk worden beloond</li>\n" +
              "<li>Verloning rekening houdend met eventueel exclusieve terbeschikkingstelling</li>\n" +
              "<li>Alle documentatie en data wordt versleuteld vastgelegd in het systeem. Dit systeem is gebouwd op de nieuwste technologie en garandeert dat gegevens automatisch na de wettelijke termijn wordt verwijderd. Het systeem is volledig transparant</li>\n" +
              "<li>Data blijft ten alle tijden eigendom van de medewerker</li>\n" +
              "<li>Rekening te houden met de vernieuwde transitieregeling</li></ul>"
            }
          />
        }
      />
      <br />
      <br />
      <br />
      <CollapsibleBlockComponent title={"Veelgestelde vragen"} collapsibles={collapsibleList()} />
      <br />
      <br />
      <br />
      <FooterComponent />
    </div>
  );
};

export { WerkgeverPage };

const collapsibleList = (): ICollapsibleComponentProps[] => [
  {
    title: "Kan ik bij Samsam een jobcoach inhuren?",
    backgroundColour: "#54cefd",
    text:
      "Ja dat kan, wij hebben onze eigen jobcoaches die ook extern ingezet kunnen worden. Op deze manier ondersteunen wij organisaties bij het ontwikkeling van hun SROI mogelijkheden."
  },
  {
    title: "Ik heb een vacature, kan ik deze plaatsen bij Samsam?",
    backgroundColour: "#54cefd",
    text:
      "Ja, stuur de specificaties van de vacature door, en dan verzorgen wij ervoor dat de juiste kandidaat snel gevonden wordt. Of het hier nou gaat om een normale functie of een functie die meer in lijn ligt met MVO of SROI ondernemen maakt niet uit. Wij helpen je de geschikte kandidaat te vinden."
  },
  {
    title: "Ik heb met spoed een kandidaat nodig. Is Samsam hiervoor de juiste partner?",
    backgroundColour: "#54cefd",
    text:
      "Ik heb met spoed een kandidaat nodig. Is Samsam hiervoor de juiste partner?” antwoord: “Ja, wij hebben altijd een groot aantal geschikte kandidaten beschikbaar, en daarnaast hebben wij kanalen om snel de juiste kandidaat te vinden. "
  },
  {
    title: "Welke soorten personeel zijn beschikbaar via Samsam?",
    backgroundColour: "#54cefd",
    text:
      "Wij hebben een uitgebreid kandidatenbestand, met een specialisatie in kandidaten met een afstand tot de arbeidsmarkt."
  },
  {
    title: "Welke begeleiding krijgt de kandidaat, en werkgever?",
    backgroundColour: "#54cefd",
    text:
      "We bieden individuele maatwerktrajecten aan, die zijn toegespitst op de behoeften van kandidaat, en werkgever. Wat dit precies inhoudt is dus verschillend voor elke kandidaat. In dit traject betrekken wij ook altijd sterk de werkgever van een kandidaat. Op deze manier zorgen wij ervoor de de begeleiding vanuit Samsam zo goed mogelijk is afgestemd op de wensen van zowel de werknemer als de werkgever"
  },
    {
        title: "Welke subsidiemogelijkheden zijn er?",
        backgroundColour: "#54cefd",
        text:
            "Er zijn verschillende subsidies mogelijkheden binnen de MVO ondernemen en SROI focus. De meest bekende zijn de loonkostensubsidie en forfaitaire subsidie aan te vragen voor kandidaten die hiervoor in aanmerking komen. Daarnaast zijn er echter ook nog een groot aantal andere mogelijkheden waarop ondernemingen financieel ondersteund worden wanneer zij hun steentje bijdrage aan de maatschappij. Voor deze informatie kunt u altijd even contact met ons opnemen"
    },
    {
        title: "Wat betekent MVO ondernemen binnen Samsam?",
        backgroundColour: "#54cefd",
        text:
            "MVO staat voor Maatschappelijk Verantwoord Ondernemen. Wat wij verstaan onder MVO is uiteen gezet in een van de documenten hierboven op deze pagina. In het kort betekent het dat wij een een positieve bijdrage willen leveren aan de maatschappij. Dat doen wij door mensen met een afstand tot de arbeidsmarkt te helpen bij het vinden van een geschikte baan. Wij vinden namelijk dat iedereen de kans moet krijgen om een leuke baan te hebben"
    },
    {
        title: "Kan Samsam helpen bij SROI ondernemen?",
        backgroundColour: "#54cefd",
        text:
            "Zeker, als bedrijf zijn wij gespecialiseerd in het helpen van organisatie met het inrichten van hun structuur om op een sociaal niveau bij te dragen aan de maatschappij. Denk hierbij aan het creëren van geschikte functie, trainings trajecten of het opleiden van job coaches. Daarnaast staan we natuurlijk ook open voor het ondersteunen van elke andere social return on investment."
    }
];
const iconButtonDownload = (): IIconButtonComponentProps[] => [
  {
    title: "Formulier nr. 1",
    link: process.env.PUBLIC_URL + Urenbrief,
    icon: PLAY_SVG,
    backgroundColour: "#54cefd",
    description: "Urenbrief Samsam"
  },
  {
    title: "Formulier nr. 2",
    link: process.env.PUBLIC_URL + AlgemenVoorwaarden,
    icon: PLAY_SVG,
    backgroundColour: "#54cefd",
    description: "Algemene informatie Samsam"
  },
    {
        title: "Formulier nr. 3",
        link: process.env.PUBLIC_URL + Maatschappelijk,
        icon: PLAY_SVG,
        backgroundColour: "#54cefd",
        description: "Maatschappelijk verantwoord ondernemen"
    }
];
