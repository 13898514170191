import * as React from "react";
import styles from "./title-component.module.scss";

export interface ITitleComponentProps {
  title: string;
  backgroundColour: string;
}

const TitleComponent = (props: ITitleComponentProps) => {
  return (
    <div className={styles["title"]} style={{ background: props.backgroundColour }}>
      <h1>{props.title}</h1>
    </div>
  );
};

export { TitleComponent };
