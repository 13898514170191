import * as React from "react";
import styles from "./recent-jobs-component.module.scss";
import { IJobCardComponentProps, JobCardComponent } from "components/core/job-card";

export interface IRecentJobsComponentProps {
  jobsCards: IJobCardComponentProps[];
}

const RecentJobsComponent = (props: IRecentJobsComponentProps) => {
  return (
    <div className={styles["recent-jobs"]}>
      <div className={styles["recent-jobs__title"]}>
        <h2>Recente jobs</h2>
      </div>
      <div className={styles["recent-jobs__cards-container"]}>
      {props.jobsCards.filter((card, idx) => idx < 6).map((card, index) => (
          <JobCardComponent
              key={index}
              id={card.id}
              title={card.title}
              category={card.category}
              location={card.location}
          />
      ))}
      </div>
    </div>
  );
};

export { RecentJobsComponent };
