import * as React from "react";
import styles from "./welcome-titles-component.module.scss";

export interface IWelcomeTitlesComponentProps {
  leftText: string;
  rightText: string;
  leftBackground: string;
  rightBackground: string;
  flat?: boolean;
  spaced?: boolean;
}

const WelcomeTitlesComponent = (props: IWelcomeTitlesComponentProps) => {
  return (
    <div className={styles["welcome-titles"]}>
      <div className={styles["welcome-titles__box"]} style={props.flat ? { backgroundColor: props.leftBackground, minHeight: 'unset' } : { backgroundColor: props.leftBackground }}>
        <div className={styles["welcome-titles__text"]} style={props.spaced ? { width: '90%' } : {  }}>
          <h1>{props.leftText}</h1>
        </div>
      </div>

      <div className={styles["welcome-titles__box"]} style={props.flat ? { backgroundColor: props.leftBackground, minHeight: 'unset' } : { backgroundColor: props.leftBackground }}>
        <div className={styles["welcome-titles__text"]}>
          <h1>{props.rightText}</h1>
        </div>
      </div>
    </div>
  );
};

export { WelcomeTitlesComponent };
