import React from "react";
import ReactDOM from "react-dom";

import { Routes } from "./route";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import "./fonts/index.scss";
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const tagManagerArgs = {
    gtmId: 'GTM-WMTDSV8'
}

ReactGA.initialize('UA-157394771-5');
ReactGA.pageview(window.location.pathname + window.location.search);

TagManager.initialize(tagManagerArgs)

// import ReactPixel from 'react-facebook-pixel';
//
// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
// const options = {
//     autoConfig: true, 	// set pixel's autoConfig
//     debug: false, 		// enable logs
// };
// // @ts-ignore
// ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);
//
// ReactPixel.pageView(); 					// For tracking page view


ReactDOM.render(<Routes />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
