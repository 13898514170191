import React from "react";

import styles from "./contact.module.scss";
import { Button } from "components/core/button";
import { ContactFormComponent } from "components/modules/contact-form";

import { NavbarComponent } from "components/modules/navbar";
import { FooterComponent } from "components/modules/footer";

import Letter_PNG from "assets/icon/letter.png";
import { TitleComponent } from "components/modules/title";

const ContactPage = () => {
  return (
    <div>
      <NavbarComponent leftBackground={"#ffec67"} rightBackground={"#ffec67"} />

      <TitleComponent backgroundColour={"#ffec67"} title={"Contact"} />

      <ContactFormComponent backgroundColour="#ffec67"/>

      <FooterComponent />
    </div>
  );
};

export { ContactPage };
