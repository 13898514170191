import * as React from "react";
import styles from "./person-card-component.module.scss";
import PERSON_SVG from "assets/icon/person.svg";
import MAIL_SVG from "assets/icon/mail.svg";
import LINKEDIN_SVG from "assets/icon/linkedin-white-background.svg";
export interface IPersonCardComponentProps {
  namePerson: string;
  job: string;
  image: string;
  email: string;
  linkedIn: string;
}

const PersonCardComponent = (props: IPersonCardComponentProps) => {
  return (
    <div className={styles["person-card"]}>
      <div className={styles["person-card__left-side"]}>
        <h4>{props.namePerson}</h4>
        <span>{props.job}</span>
        <div className={styles["person-card__icons"]}>
            <a target={"_blank"} href={"mailto:" + props.email}><img src={MAIL_SVG} /></a>
            <a target={"_blank"} href={"https://www.linkedin.com/in/" + props.linkedIn}><img src={LINKEDIN_SVG} /></a>
        </div>
      </div>
      <div className={styles["person-card__right-side"]}>
        <img src={props.image} />
      </div>
    </div>
  );
};

export { PersonCardComponent };
