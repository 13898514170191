import * as React from "react";

import styles from "./basic-arrow.module.scss";
import ARROW_SVG from "assets/icon/arrow.svg";
interface IBasicArrowProps {
  className?: string;
  customArrow?: JSX.Element;
  onClick?: () => void;
  prev?: boolean;
}

const BasicArrow = (props: IBasicArrowProps) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${styles["basic-arrow"]} ${props.prev ? styles["basic-arrow__prev"] : styles["basic-arrow__next"]} `}
      onClick={onClick}
    >
      <img className={className} src={ARROW_SVG} />
    </div>
  );
};
export { BasicArrow };
