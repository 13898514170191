import * as React from "react";
import styles from "./vacancies-component.module.scss";
import { JobCardComponent } from "components/core/job-card";
import axios from "axios";

export interface IVacanciesComponentProps {
    vacancies: {id: string, title: string, category: string, location: string, img: string}[],
    onCategoryClick?: any,
}

const VacanciesComponent = (props: IVacanciesComponentProps) => {
    const [categories, setCategories] = React.useState<string[]>([]);
    const [categoriesWithVacanciesCount, setCategoriesWithVacanciesCount] = React.useState<any>({
        'Alle': 0
    });

    React.useEffect(() => {
        let url = '/api/category/all';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                setCategories(data.data);
            });
    }, []);

    React.useEffect(() => {
        categoriesWithVacanciesCount['Alle'] = 0;
        categories.forEach(category => {
            categoriesWithVacanciesCount[category] = 0;
        });

        props.vacancies.forEach(vacancy => {
            categoriesWithVacanciesCount[vacancy.category]++;
            categoriesWithVacanciesCount['Alle']++;
        });

        setCategoriesWithVacanciesCount(
            Object.assign({}, categoriesWithVacanciesCount)
        );
    }, [props.vacancies, categories]);

    return (
        <div className={styles["vacancies"]}>
            <div className={styles["vacancies-container"]}>
                <div className={styles["vacancies-categories"]}>
                    <div className={styles["vacancies-categories__title"]}>
                        Categorieën
                    </div>
                    <div className={styles["vacancies-categories__item"]} onClick={() => props.onCategoryClick('')}>
                        Alle
                        <div className={styles["vacancies-categories__item-number"]}>
                            { categoriesWithVacanciesCount['Alle'] }
                        </div>
                    </div>
                    {
                        categories.map((category) => (
                            <div key={category} className={styles["vacancies-categories__item"]} onClick={() => props.onCategoryClick(category)}>
                                { category }
                                <div className={styles["vacancies-categories__item-number"]}>
                                    { categoriesWithVacanciesCount[category] || 0 }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={styles["vacancies-sectors"]}>
                    {
                        props.vacancies.map((vacancy) => {
                            return <div key={vacancy.id} className={styles["vacancies-sectors__vacancy"]}>
                                <JobCardComponent
                                    id={vacancy.id}
                                    title={vacancy.title}
                                    category={vacancy.category}
                                    location={vacancy.location}
                                    img={vacancy.img}
                                />
                              </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export { VacanciesComponent };
