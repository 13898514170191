import React from "react";
import styles from "./homepage.module.scss";

import { WelcomeComponent } from "components/modules/welcome";
import pictuere1 from "assets/pictures/business-person4.jpg";
import pictuere2 from "assets/pictures/werkgever.png";

import { NavbarComponent } from "components/modules/navbar";
import { ContactFormComponent } from "components/modules/contact-form";
import { Link } from "react-router-dom";
import { RecentJobsComponent } from "components/modules/recent-jobs";

import { SearchByCategoryComponent } from "components/modules/search-by-category";
import { FooterComponent } from "components/modules/footer";
import { SearchComponent } from "components/core/search";
import { CtaComponent } from "components/modules/cta";
import { BodyTextComponent } from "components/modules/body-text";
import { RelatedNewsComponent } from "../../modules/related-news/related-news.component";
import { WelcomeTitlesComponent } from "components/modules/welcome-titles";
import { Button } from "components/core/button";
import axios from "axios";

const onSearchByTerm = (text: string, props: any) => {
  props.history.push('vacancies?search=' + text);
};

const onSearchByCategory = (text: string, props: any) => {
  props.history.push('vacancies?category=' + text);
};

const Homepage = (props: any) => {

    const [jobsCards, setJobsCards] = React.useState<{title: string, category: string, location: string, textViewButton: string, textApplyJobButton: string}[]>([]);
    const [categoriesCards, setCategoriesCards] = React.useState<{sectorName: string, icon: string, onClick: () => void}[]>([]);

    React.useEffect(() => {
        let url = '/api/vacancy/list';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                setJobsCards(data.data.map((vacancy: any) => {
                    return {
                        id: vacancy.id.toString(),
                        title: vacancy.internal_title,
                        category: vacancy.category.title,
                        location: `${vacancy.province} ${vacancy.postal_code} ${vacancy.city} ${vacancy.country}`,
                    };
                }));
            });
    }, []);

    React.useEffect(() => {
        let url = '/api/category/getWithVacanciesCount';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }
        axios.get(url)
            .then(({data}) => {
                setCategoriesCards(data.categories.map((category: any) => {
                    return {
                        sectorName: category.title,
                        icon: category.image,
                    };
                }));
            });
    }, []);

  return (
    <div>
      <NavbarComponent leftBackground={"#01a656"} rightBackground={"#54cefd"} />
      <WelcomeComponent
        leftBackground={"#01a656"}
        leftText={"Samsam maatschappelijk verantwoord ondernemen."}
        rightText={"Creëer een kans voor iemand die deze nodig heeft met Samsam."}
        rightBackground={"#54cefd"}
        titleLeftButton={"Werk zoeken"}
        linkLeftButton={"/vacancies"}
        titleRightButton={"Voor werkgevers"}
        linkRightButton={"/werkgever"}
        pictures={[pictuere1, pictuere2]}
      />
      <RecentJobsComponent jobsCards={jobsCards} />
      <br /> <br /> <br />
      <CtaComponent image={pictuere1} position={"right"}>
        <BodyTextComponent
          text="Samsam is hier om jou verder te helpen op het gebied van maatschappelijk verantwoord ondernemen. Wij helpen daarbij door het creëren van mogelijkheden voor MVO ondernemen binnen organisaties. En tegelijkertijd zijn we er voor de andere werknemers en kandidaten om zich te ontwikkelen, iets wat soms makkelijker klinkt dan het daadwerkelijk is. Dit doen wij op een zo transparant en eerlijk mogelijke manier. Waar wij ons aan de ene kant inzetten om voor werkzoekende de juiste plek te vinden om aan de slag te gaan, bieden wij ook de mogelijkheid om deze werkplekken aan te bieden als werkgever. Hierbij focussen wij ons sterk op een goede social return on investments voor bedrijven en organisaties. Samsam dient als tussenpersoon die het allemaal net even wat makkelijker maakt voor beide partijen. Deel jouw wensen met ons en wij gaan voor jou aan de slag!"
          isSplit
          title={"Samsam is de schakel tussen werknemers en maatschappelijk verantwoorde werkgevers"}
          background={"#01a656"}
        />
      </CtaComponent>
      <SearchByCategoryComponent categoryNames={categoriesCards} onSearch={category => onSearchByCategory(category, props)}/>
      <SearchComponent
        buttonText={"Zoeken"}
        title={"Of zoek op een kernwoord"}
        placeholder={"Typ hier een kernwoord…"}
        onSearch={ text => onSearchByTerm(text, props) }
      />
      {/*<div className={styles['news-related-title']}>Recent nieuws</div>*/}
      {/*<RelatedNewsComponent />*/}
      {/*<div style={{margin: '2rem', textAlign: 'center'}}>*/}
        {/*<Link to={"/news"}>*/}
          {/*<Button style={{width: 'auto'}} text="Meer nieuws" variant={"secondary"} />*/}
        {/*</Link>*/}
      {/*</div>*/}
      <WelcomeTitlesComponent
          leftBackground={"#ffec67"}
          rightBackground={"#ffec67"}
          leftText={"Klaar om een nieuwe stap te zetten, een nieuwe kans te creëren?"}
          rightText={"Contact"}
          flat
          spaced
      />
      <ContactFormComponent backgroundColour="#ffec67"/>
      <FooterComponent />
    </div>
  );
};

export { Homepage };
