import * as React from "react";
import styles from "./cta-component.module.scss";

export interface ICtaComponentProps {
  image: string;
  children?: JSX.Element;
  position?: "left" | "right";
}

const CtaComponent = (props: ICtaComponentProps) => {
  return (
    <div className={styles["cta"]}>
      <div className={styles["cta__content"]}>{props.children}</div>
      <div
        className={`${styles["cta__image"]} ${
          props.position ? styles[`cta__image-${props.position}`] : styles["cta__image-left"]
        }`}
        style={{backgroundImage: 'url(' + props.image + ')'}}
      >
      </div>
    </div>
  );
};

export { CtaComponent };
