import React from "react";

import { NavbarComponent } from "components/modules/navbar";

import { FooterComponent } from "components/modules/footer";
import { VacanciesComponent } from "components/modules/vacancies";
import { VacanciesFilterComponent } from "components/modules/vacancies-filter";
import axios from "axios";

const VacanciesPage = (props: any) => {
    const [cancelTokenSource, setCancelTokenSource] = React.useState<any>(null);

    const searchVacancies = function(term = '', province = '', city ='', category = '') {

        if (cancelTokenSource) {
            cancelTokenSource.cancel();
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setCancelTokenSource(source);

        let url = '/api/vacancy/list';
        if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_BACKEND_URL + url;
        }

        axios.get(url, {
            params: { term, province, city, category, },
            cancelToken: source.token
        })
            .then(({data}) => {
                let vacancies = data.data.map((vacancy: any) => {
                    return {
                        id: vacancy.id,
                        title: vacancy.internal_title,
                        category: vacancy.category.title,
                        location: `${vacancy.province} ${vacancy.postal_code} ${vacancy.city} ${vacancy.country}`,
                        img: vacancy.category.image,
                    }
                });
                setVacancies(vacancies);
            });
    };

    const [searchTerm, setSearchTerm] = React.useState('');
    const [categoryTerm, setCategoryTerm] = React.useState('');
    const [vacancies, setVacancies] = React.useState<{id: string, title: string, category: string, location: string, img: string}[]>([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let search = props.location.search.substring(1);
        let searchSegments = search.split('&');
        let searchKeyValues = searchSegments.filter((segment: any) => {
            let key = segment.split('=')[0];

            return key === 'search';
        });

        if (searchKeyValues.length) {
            setSearchTerm(searchKeyValues[0].split('=')[1]);
        }

        let categoryKeyValues = searchSegments.filter((segment: any) => {
            let key = segment.split('=')[0];

            return key === 'category';
        });

        if (categoryKeyValues.length) {
            setCategoryTerm(categoryKeyValues[0].split('=')[1]);
        }
    }, []);

    return (
        <div>
            <NavbarComponent leftBackground={"#54cefd"} rightBackground={"#54cefd"} />
            <VacanciesFilterComponent background={"#54cefd"} title={"Vacatures"} searchTerm={searchTerm} categoryTerm={categoryTerm} search={searchVacancies} />
            <VacanciesComponent vacancies={vacancies} onCategoryClick={(category:string) => setCategoryTerm(category)}/>
            <FooterComponent />
        </div>
    );
};

export { VacanciesPage };
