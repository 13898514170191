import * as React from "react";
import styles from "./join-component.module.scss";

export interface IJoinComponentProps {
  componentLeftSide?: JSX.Element;
  componentRightSide?: JSX.Element;
}

const JoinComponent = (props: IJoinComponentProps) => {
  return (
    <div className={styles["join"]}>
      <div>{props.componentLeftSide}</div>
      <div>{props.componentRightSide}</div>
    </div>
  );
};

export { JoinComponent };
