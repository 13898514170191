import * as React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Homepage } from "components/pages/homepage";
import { VacanciesPage } from "components/pages/vacancies";
import { VacancyViewPage } from "components/pages/vacancy-view";
import { NewsPage } from "components/pages/news";
import { NewsDetailsPage } from "components/pages/news-details";
import { ContactPage } from "components/pages/contact";
import { SectorenPage } from "components/pages/sectoren";
import { SupportPage } from "components/pages/support";
import { UitzendkrachtPage } from "components/pages/uitzendkracht";
import { WerkgeverPage } from "components/pages/werkgever";
import { NoMatchPage } from "components/pages/noMatch";

const Routes: React.FunctionComponent<{}> = props => (
  <React.Fragment>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/vacancies" component={VacanciesPage} />
        <Route path="/vacancy-view/:id" component={VacancyViewPage} />
        {/*<Route exact path="/news" component={NewsPage} />*/}
        {/*<Route path="/news/:id" component={NewsDetailsPage} />*/}
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/sectoren" component={SectorenPage} />
        <Route exact path="/reintegratie" component={SupportPage} />
        <Route exact path="/uitzendkracht" component={UitzendkrachtPage} />
        <Route exact path="/werkgever" component={WerkgeverPage} />
        <Route path="*" component={NoMatchPage} />
      </Switch>
    </BrowserRouter>
  </React.Fragment>
);

export { Routes };
