import * as React from "react";
import styles from "./collapsible-block-component.module.scss";
import { ICollapsibleComponentProps, CollapsibleComponent } from "components/core/collapsible";

export interface ICollapsibleBlockComponentProps {
  collapsibles: ICollapsibleComponentProps[];
  title: string;
}

const CollapsibleBlockComponent = (props: ICollapsibleBlockComponentProps) => {
  return (
    <div className={styles["collapsible-block"]}>
      <div className={styles["collapsible-block__title"]}>
        <h3>{props.title}</h3>
      </div>
      <div className={styles["collapsible-block__content"]}>
        {props.collapsibles.map((col, index) => (
          <CollapsibleComponent text={col.text} backgroundColour={col.backgroundColour} title={col.title} key={index} />
        ))}
      </div>
    </div>
  );
};

export { CollapsibleBlockComponent };
