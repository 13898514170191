import React from "react";

import { WelcomeComponent } from "components/modules/welcome";

import PLAY_SVG from "assets/icon/play.svg";
import POSITION_PNG from "assets/pictures/position.png";
import LETTER_PNG from "assets/pictures/letter_.png";
import PHONE_PNG from "assets/pictures/phone.png";
import { NavbarComponent } from "components/modules/navbar";

import { FooterComponent } from "components/modules/footer";

import { TitleComponent } from "components/modules/title";

import { IIconButtonComponentProps } from "components/modules/icon-button";
import { IconButtonsBlockComponent } from "components/modules/icon-buttons-block";
import { JoinComponent } from "components/core/join";
import { BodyText2Component } from "components/modules/body-text-2";
import { PersonCardComponent } from "components/modules/person-card";
import { CtaComponent } from "components/modules/cta";
import pictuere1 from "assets/pictures/business-person5.jpg";
import { TeamComponentComponent } from "components/modules/team-component";
import styles from "./support.module.scss";
import {WelcomeTitlesComponent} from "../../modules/welcome-titles";

const SupportPage = () => {
  return (
    <>
      <NavbarComponent leftBackground={"#e0e0e0"} rightBackground={"#e0e0e0"} />
        <WelcomeTitlesComponent
            leftBackground={"#e0e0e0"}
            rightBackground={"#e0e0e0"}
            leftText={"Re-integratie en Jobcoaching"}
            rightText={"Een multidisciplinaire aanpak bij de ontwikkeling van kandidaten en de ontwikkeling van organisaties."}
        />

        <CtaComponent image={pictuere1} position={"right"}>
            <BodyText2Component
                uppertext={"Samsam's aanbod van re-integratie expertise is onder te verdelen in de volgende vlakken:"}
                text={
                    "Samsam is gespecialiseerd in het beste naar boven halen van de talenten bij de cliënten. De medewerkers die de cliënt begeleiden en trainen zijn specifiek geschoold op het gebied van pedagogiek, analyseren (assessment) en (job)coaching. Samsam heeft een groot netwerk waar cliënten hun werkervaring op kunnen  doen. Voor partijen die SROI inzetten zijn wij een veel gevraagde partner omdat wij kennis van de doelgroep hebben en zowel de cliënt als de opdrachtgever hierin begeleiden wat betreft het opdoen van werkervaring. Daarnaast kan de cliënt bij Samsam onder dezelfde begeleiding het gehele (UWV-)traject doorlopen en ligt er sterke nadruk op blijvende ondersteuning na afloop van het (UWV-)traject.<br><br>Omdat Samsam ook als uitzender actief is kan na een eventuele afronding van het UWV-traject een blijvende relatie worden opgebouwd met de cliënt en kan de cliënt worden opgenomen in de uitzendpoule."
                }
                isSplit
                title={"Re-integratie"}
                title2={"Jobcoaching"}
                text2={"Samsam heeft ervaring met verschillende assessment en begeleidingsmethodes en heeft coaches ter beschikking die ervaring hebben in het begeleiden van cliënten van alle achtergronden en opleidingsniveaus. Zo kan er zowel voor laagopgeleiden als hoogopgeleiden geschikte begeleiding worden aangeboden.<br><brSamsam's coaches hebben ervaring met het starten en runnen van een eigen onderneming. Dus ook cliënten met ZZP- of ondernemers ambities kunnen geholpen worden bij Samsam.<br><br>Fysiek welzijn is ook van invloed op het ervaren van werk. Samsam kijkt bij het assessment ook naar het fysieke aspect van de cliënt en welke impact dit kan hebben op het Werk-fit zijn."}
                background={"#fff"}
                buttonLink={'/contact'}
                buttonText={"Neem contact op"}
            />
        </CtaComponent>
      <br />
      <br />
      <TeamComponentComponent />
      <FooterComponent />
    </>
  );
};

export { SupportPage };

const iconButtonDownload = (): IIconButtonComponentProps[] => [
  {
    title: "Formulier nr. 1",
    link: "/formulieren/",
    icon: PLAY_SVG,
    backgroundColour: "#ffec67",
    description: "Geen privé gebruik auto"
  },
  {
    title: "Formulier nr. 1",
    link: "/formulieren/",
    icon: PLAY_SVG,
    backgroundColour: "#ffec67",
    description: "Geen privé gebruik auto"
  },
  {
    title: "Formulier nr. 1",
    link: "/formulieren/",
    icon: PLAY_SVG,
    backgroundColour: "#ffec67",
    description: "Geen privé gebruik auto"
  }
];
