import * as React from "react";
import styles from "./icon-button-component.module.scss";
import ReactSVG from "react-svg";

export interface IIconButtonComponentProps {
  icon: string;
  title: string;
  description: string;
  backgroundColour: string;
  link: string;
}

const IconButtonComponent = (props: IIconButtonComponentProps) => {
    return (
    <a target="_blank" href={props.link}>
        <div className={styles["icon-button"]} style={{ backgroundColor: props.backgroundColour }}>
          <div className={styles["icon-button__hover"]} />
          <ReactSVG src={props.icon} />
          <span>{props.title} </span>
          <h4>{props.description}</h4>
        </div>
    </a>
  );
};

export { IconButtonComponent };
