import * as React from "react";
import styles from "./collapsible-component.module.scss";
import PLUS_SVG from "assets/icon/plus.svg";
import MINUS_SVG from "assets/icon/minus.svg";
import ReactSVG from "react-svg";
export interface ICollapsibleComponentProps {
  backgroundColour: string;
  text: string;
  title: string;
}

const CollapsibleComponent = (props: ICollapsibleComponentProps) => {
  const [isCollapsibleOpened, setIsCollapsibleOpened] = React.useState(false);
  return (
    <div className={styles["collapsible"]} style={{ background: props.backgroundColour }}>
      <div className={styles["collapsible__button"]} onClick={() => setIsCollapsibleOpened(!isCollapsibleOpened)}>
        <div className={styles["collapsible__icon"]}>
          <img src={isCollapsibleOpened ? MINUS_SVG : PLUS_SVG} />
        </div>

        <h4>{props.title}</h4>
      </div>
      <div
        className={`${styles["collapsible__content"]} ${
          isCollapsibleOpened ? styles["collapsible__content-opened"] : ""
        }`}
      >
        <div className={styles["collapsible__hover"]} />
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export { CollapsibleComponent };
