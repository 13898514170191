import * as React from "react";
import styles from "./icon-buttons-block-component.module.scss";
import { IconButtonComponent, IIconButtonComponentProps } from "../icon-button/icon-button.component";

export interface IIconButtonsBlockComponentProps {
  iconButtons: IIconButtonComponentProps[];
}

const IconButtonsBlockComponent = (props: IIconButtonsBlockComponentProps) => {
  return (
    <div className={styles["icon-buttons-block"]}>
      {props.iconButtons.map((button, index) => (
        <IconButtonComponent
          link={button.link}
          key={index}
          backgroundColour={button.backgroundColour}
          description={button.description}
          icon={button.icon}
          title={button.title}
        />
      ))}
      <i aria-hidden={"true"} />
    </div>
  );
};

export { IconButtonsBlockComponent };
