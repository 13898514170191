import * as React from "react";
import styles from "./footer-component.module.scss";
import INSTAGRAM_GREEN_SVG from "assets/icon/instagram-green.svg";
import FACEBOOK_GREEN_SVG from "assets/icon/facebook-green.svg";
import LINKEDIN_GREEN_SVG from "assets/icon/linkedin-green.svg";
const Maatschappelijk = "/documents/Maatschappelijk_Verantwoord_Ondernemen.pdf";

export interface IFooterComponentProps {}

const FooterComponent = (props: IFooterComponentProps) => {
  return (
    <div className={styles["footer"]}>
      <div className={styles["footer__center"]}>
        <div className={styles["footer__center__social"]}>
          <div className={styles["footer__top"]}>
            <h3>samsam</h3>
          </div>
          <div className={styles["footer__center__column-content"]}>
            <a href={process.env.REACT_APP_INSTAGRAM}>
              <div>
                <img src={INSTAGRAM_GREEN_SVG} />
              </div>
              Instagram
            </a>
            <a href={process.env.REACT_APP_FACEBOOK}>
              <div>
                <img src={FACEBOOK_GREEN_SVG} />
              </div>
              Facebook
            </a>
            <a href={process.env.REACT_APP_LINKEDIN}>
              <div>
                <img src={LINKEDIN_GREEN_SVG} />
              </div>
              Linkedin
            </a>
          </div>
        </div>
        <div className={styles["footer__center__jobs"]}>
          <div className={styles["footer__center__column-title"]}>
            <h4>Verdieping</h4>
          </div>
          <div className={styles["footer__center__column-content"]}>
            <a href="/vacancies">Vacatures</a>
            <a href="/uitzendkracht">Uitzendkracht</a>
            <a href="/werkgever">Werkgever</a>
            <a href="/sectoren">Sectoren</a>
            {/*<a href="/news">Nieuws</a>*/}
            <a href="/reintegratie">Reïntegratie</a>
          </div>
        </div>
        <div className={styles["footer__center__jobs"]}>
          <div className={styles["footer__center__column-title"]}>
            <h4>Op zoek naar</h4>
          </div>
          <div className={styles["footer__center__column-content"]}>
            <a href={process.env.REACT_APP_WEBSITE_URL + "/documents/Maatschappelijk_Verantwoord_Ondernemen.pdf"}>Wat is MVO ondernemen?</a>
          </div>
        </div>
        <div className={styles["footer__center__info"]}>
          <div className={styles["footer__center__column-title"]}>
            <h4>Contact informatie</h4>
          </div>
          <div className={styles["footer__center__column-content"]}>
            <a>Kerkenbos 1075V, 6546BB, Nijmegen</a>
            <a>welkom@samsam-mvo.nl</a>
            <a>+31 (0) 88 711 33 33</a>
          </div>
        </div>
      </div>
      <div className={styles["footer__bottom"]}>
        <span>
          Copyright 2019 - samsam
        </span>
      </div>
    </div>
  );
};

export { FooterComponent };
