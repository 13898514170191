import React from "react";

import pictuere1 from "assets/pictures/business-person6.jpg";

import { NavbarComponent } from "components/modules/navbar";
import PLAY_SVG from "assets/icon/play.svg";

import { ICategoryCardComponentProps } from "components/core/category-card";
import { FooterComponent } from "components/modules/footer";

import { CtaComponent } from "components/modules/cta";

import { BodyTextComponent } from "components/modules/body-text";
import { WelcomeTitlesComponent } from "components/modules/welcome-titles";
import { IconButtonsBlockComponent } from "components/modules/icon-buttons-block";
import { IIconButtonComponentProps } from "components/modules/icon-button";
import { CollapsibleComponent, ICollapsibleComponentProps } from "components/core/collapsible";
import { CollapsibleBlockComponent } from "components/modules/collapsible-block";
import { JoinComponent } from "components/core/join";
const AlgemenVoorwaarden = "/documents/algemenen.pdf";
const Urenbrief = "/documents/urenbrief.pdf";
const Maatschappelijk = "/documents/Maatschappelijk_Verantwoord_Ondernemen.pdf";

const onSearchCategory = (text: string) => {
  // category search, move it the the parent component
  alert(text);
};
const UitzendkrachtPage = () => {
  return (
    <div>
      <NavbarComponent leftBackground={"#01a656"} rightBackground={"#01a656"} />
      <WelcomeTitlesComponent
        leftBackground={"#01a656"}
        rightBackground={"#01a656"}
        leftText={"De uitzendkracht"}
        rightText={"Samen vinden wij de plek die het best bij jou past."}
      />
      <CtaComponent image={pictuere1} position={"right"}>
        <BodyTextComponent
          text={
            "Samsam is de partij die zich volledig zal inzetten om de juiste plek te vinden waar jij aan het\n" +
            "werk kunt. Samen werken wij aan een duurzame en mooie toekomst voor iedereen en daar\n" +
            "dragen wij graag aan mee. Dit doen wij door middel van het aanbod aan werk binnen een aantal\n" +
            "verschillende sectoren. Dankzij onze goede relaties met de werkgevers zorgen wij altijd voor\n" +
            "optimale werkcondities en minimale wachttijden binnen het zoekprocess. Wij luisteren naar jouw\n" +
            "wensen, nemen dit in ons op en gaan vervolgens op zoek naar de geschikte match tussen jou\n" +
            "en jouw toekomstige werkgever.\n" +
            "Mocht je tijdens de zoektocht naar een geschikte werkplek tot de realisatie komen dat je niet\n" +
            "beschikt over de juiste scholing voor jouw droomfunctie? Geen zorgen. Samsam biedt de\n" +
            "mogelijkheid om op ieder moment van je carrière in te stromen en door middel van een\n" +
            "combinatie van scholing en gepaste begeleiding de weg vrij te maken naar jouw droomplek.\n" +
            "Ben jij klaar om het werkveld te betreden? Wij gaan graag met je in gesprek over jouw wensen\n" +
            "en wat hierbij de mogelijkheden zijn. Samen zullen wij de mogelijkheden doornemen en kijken\n" +
            "wat de volgende stap is in jouw zoektocht. Kijk je liever rond op eigen houtje, dan kun je onze\n" +
            "vacatures bekijken en direct solliciteren met jouw motivatie en actuele versie van je CV."
          }
          isSplit
          title={"Informatie"}
          background={"#fff"}
          buttonLink={'/contact'}
          buttonText={"Meld je nu aan"}
        />
      </CtaComponent>

      <JoinComponent componentLeftSide={<IconButtonsBlockComponent iconButtons={iconButtonDownload()} />} />
      <br />
      <br />
      <CollapsibleBlockComponent title={"Veelgestelde vragen"} collapsibles={collapsibleList()} />

      <FooterComponent />
    </div>
  );
};

export { UitzendkrachtPage };

const collapsibleList = (): ICollapsibleComponentProps[] => [
  {
    title: "Wanneer krijg ik salaris?",
    backgroundColour: "#01a656",
    text:
      "Standaard word je op donderdag uitbetaald mits die opgegeven uren op tijd zijn binnengekomen en goedgekeurd. Mocht dit voor jou nou niet goed uitkomen dan kunnen we daar altijd over in gesprek treden."
  },
  {
    title: "Bouw ik vakantiedagen op?",
    backgroundColour: "#01a656",
    text:
      "Ja, je bouwt de wettelijke hoeveelheid vakantiedagen op. Op je loonstrook kun je altijd zien hoeveel vakantiedagen je hebt opgebouwd. Mocht je dit nog onduidelijk vinden dan kun je ons altijd even bellen en dan lopen we er samen doorheen"
  },
  {
    title: "Ik zoek werk, kunnen jullie mij helpen?",
    backgroundColour: "#01a656",
    text:
      "Als je werk zoekt zit je bij ons aan het juiste adres. We zijn instaat voor iedereen een geschikte baan te vinden. Mocht je al langere periode thuis zitten dan kunnen wij je toch helpen om de arbeidsmarkt weer te betreden. Dit doen wij doormiddel van verschillende opleidingstrajecten. Daarnaast hebben we om ons heen verschillende bedrijven die open staan voor verschillende soorten nieuwe kandidaten. Mocht je vragen hebben over wat we precies voor je kunnen betekenen neem dan gerust contact met ons op. "
  },
  {
    title: "Ik zit in de participatiewet, voor welk werk kom ik in aanmerking?",
    backgroundColour: "#01a656",
    text:
      "Je komt in deze situatie in aanmerking voor een groot aantal verschillende functies. Het belangrijkste is daarom om samen in gesprek te treden en uit te zoeken waar je interesses liggen. Nadat we dat hebben bepaald kunnen we je helpen met het vinden van een geschikte baan. Iedereen heeft recht op een baan waar ze gelukkig van worden en dat is precies waar wij je bij kunnen helpen."
  },
  {
    title: "Kan ik begeleiding krijgen tijdens mijn werk, en uitzendperiode?",
    backgroundColour: "#01a656",
    text:
      "Ja, wij bieden uitgebreide begeleidingsmogelijkheden aan, aan iedereen die dit nodig heeft. Samsam beschikt over eigen jobcoaches en re-integratiebegeleiders. Welke begeleiding je precies nodig hebt zullen we na ons kennismakingsgesprek bekijken, neem daarvoor gerust contact met ons op"
  },
    {
        title: "Wat betekent MVO?",
        backgroundColour: "#01a656",
        text:
            "MVO staat voor Maatschappelijk Verantwoord Ondernemen. Wat wij verstaan onder MVO is uiteen gezet in een van de documenten hierboven op deze pagina. In het kort betekent het dat wij een een positieve bijdrage willen leveren aan de maatschappij. Dat doen wij door mensen met een afstand tot de arbeidsmarkt te helpen bij het vinden van een geschikte baan. Wij vinden namelijk dat iedereen de kans moet krijgen om een leuke baan te hebben"
    }
];
const iconButtonDownload = (): IIconButtonComponentProps[] => [
      {
          title: "Formulier nr. 1",
          link: process.env.PUBLIC_URL + Urenbrief,
          icon: PLAY_SVG,
          backgroundColour: "#01a656",
          description: "Urenbrief Samsam"
      },
    {
        title: "Formulier nr. 2",
        link: process.env.PUBLIC_URL + AlgemenVoorwaarden,
        icon: PLAY_SVG,
        backgroundColour: "#01a656",
        description: "Algemene informatie Samsam"
    },
    {
        title: "Formulier nr. 3",
        link: process.env.PUBLIC_URL + Maatschappelijk,
        icon: PLAY_SVG,
        backgroundColour: "#01a656",
        description: "Maatschappelijk verantwoord ondernemen"
    }
];
