import React from "react";

import styles from "./button.module.scss";

export type ButtonType = "primary" | "secondary";
interface IButtonProps {
  text?: string;
  variant?: ButtonType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: object;
}

const Button = (props: IButtonProps) => {
  const { variant, text, onClick, style } = props;
  const classModify = `${variant || "primary"}`;
  return (
    <React.Fragment>
      <button name={text} className={`${styles["button"]} ${styles[`button-${classModify}`]} `} onClick={onClick} style={style}>
        <span>{text}</span>
      </button>
    </React.Fragment>
  );
};

export { Button };
