import React from "react";

import { NavbarComponent } from "components/modules/navbar";

import { FooterComponent } from "components/modules/footer";
import { SectoreCardSliderComponent } from "components/modules/sector-card";
import { TitleComponent } from "components/modules/title";

const SectorenPage = (props: any) => {
  return (
    <div>
      <NavbarComponent leftBackground={"#01a656"} rightBackground={"#01a656"} />
      <TitleComponent title={"Sectoren"} backgroundColour={"#01a656"} />
      <SectoreCardSliderComponent history={props.history} />
      <FooterComponent />
    </div>
  );
};

export { SectorenPage };
